<template>
  <div class="ma-auto" />
</template>
<script>
  import axios from 'axios'
  import { mapGetters, mapActions } from 'vuex'
  import { getToken, getUserPersonID, get } from '../../../worker/worker-api'
  export default {
    name: 'Callback',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Profil',
    },
    computed: {
      ...mapGetters(['getPlanningPeriods'])
    },
    methods: {
      ...mapActions([
        'login', 
        'setRoles', 
        'setProfile', 
        'setPlanningPeriods', 
        'setCurrentRole', 
        'setCurrentPlanningPeriod',
        'signOut']),
      getProfile (Ai_UserID, As_TargetRole) {
        let Lo_CurrentPlanningPeriod;
        return get(`${process.env.VUE_APP_SMT_API_URL}/user/${Ai_UserID}`, {})
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.setProfile(response);
            this.setRoles(response.a_Roles);
            if(response.a_Roles.length > 0) this.setCurrentRole(response.a_Roles[0]);
            this.setPlanningPeriods(response.a_PlanningPeriods);
            if(As_TargetRole !== undefined){
              Lo_CurrentPlanningPeriod = this.$smt.findCurrentPeriod(response.a_PlanningPeriods, As_TargetRole);
              }
            else
              Lo_CurrentPlanningPeriod = this.$smt.findCurrentPeriod(response.a_PlanningPeriods, response.a_Roles[0].s_Role);
            this.setCurrentPlanningPeriod(Lo_CurrentPlanningPeriod);
            this.sortPlanningPeriods(response.a_AllPlanningPeriods)
          })
          .catch(error => {
            console.log('error', error)
          })
      },
      sortPlanningPeriods (Aa_PlanningPeriods) {
        let La_UnsortedPlanningPeriods = [...this.getPlanningPeriods]
        let La_SortedPlanningPeriods = []
        Aa_PlanningPeriods.forEach(period => {
          const La_Period = La_SortedPlanningPeriods.filter(sortedPeriod => sortedPeriod.i_PlanningPeriodID === period.i_PlanningPeriodID)
          if (La_Period.length < 1) {
            const Li_Index = La_SortedPlanningPeriods.findIndex(sortedPeriod => sortedPeriod.i_PrevPlanningPeriodID === period.i_PlanningPeriodID)
            if (Li_Index === -1) La_SortedPlanningPeriods.push(period)
            else La_SortedPlanningPeriods.splice(Li_Index, 1, period)
          }
        });
        La_UnsortedPlanningPeriods.forEach((period, index) => {
          const Li_Index = La_SortedPlanningPeriods.findIndex(sortedPeriod => sortedPeriod.i_PlanningPeriodID === period.planningPeriodID)
          La_UnsortedPlanningPeriods[index]['position'] = Li_Index
        })
        this.setPlanningPeriods([...La_UnsortedPlanningPeriods.sort((a, b) => a.position < b.position ? 1: -1)])
      }
    },
    async mounted () {
      const s_Url = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/token`
      const o_Params = {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
        code: this.$route.query.code,
      }
      if (await getToken(s_Url, o_Params)) {
        const i_UserID = await getUserPersonID();
        const s_TargetUrl = localStorage.getItem('targetUrl');
        const s_TargetRole = this.$smt.getRoleFromUrl(s_TargetUrl);
        this.login(i_UserID)
        if (s_TargetUrl != `/${this.$i18n.locale}/admin-ms/sa`) await this.getProfile(i_UserID, s_TargetRole)
        if (s_TargetUrl === null) this.$router.push('/')
        else this.$router.push(s_TargetUrl).catch(() => {})
      }
    },
  }
</script>
